import React, { useContext, useEffect, useRef, useState } from "react";
import tw, { css, theme } from "twin.macro";
import useSanityShopifyProducts from "~hooks/useSanityShopifyProducts.jsx";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import * as Logo from "~components/svg/Logos.jsx";
import Go from "~components/Go.jsx";
import { trackViewCart } from "~utils/analytics";

const Header = () => {
  // ===========================================================================
  // context / ref / state

  const {
    cart,
    cartActive,
    setCartActive,
    menuActive,
    setMenuActive,
    pathname
  } = useContext(AppContext);
  const { isDesktop, scrollTop, windowWidth } = useContext(DocumentContext);

  const logoRef = useRef();
  const scalingLogoRef = useRef();

  const [scalingLogoVisible, setScalingLogoVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const { allSanityShopifyProduct } = useSanityShopifyProducts();

  // ===========================================================================
  // variables

  const subnavVisible =
    !isDesktop() || (visible && (pathname !== `/` || !scalingLogoVisible));

  let cartCount = 0;

  if (cart?.[0]) {
    cart.forEach(({ quantity }) => {
      cartCount += quantity;
    });
  }

  let fixedWidth = 0;
  let scalingWidth = 0;
  let scaleTransformFactor;

  if (logoRef?.current && scalingLogoRef?.current) {
    fixedWidth = logoRef.current.getBoundingClientRect().width;
    scalingWidth = scalingLogoRef.current.getBoundingClientRect().width;
    scaleTransformFactor = fixedWidth / scalingWidth;
  }

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (cartActive) {
      trackViewCart(cart, allSanityShopifyProduct);
    }
  }, [cartActive]);

  useEffect(() => {
    if (pathname) {
      if (!visible) {
        setVisible(true);
      }
    }
  }, [pathname]);

  useEffect(() => {
    const scrollThreshold = parseInt(windowWidth / 6);

    if (scrollTop > scrollThreshold) {
      if (scalingLogoVisible) {
        setScalingLogoVisible(false);
      }
    } else if (!scalingLogoVisible) {
      setScalingLogoVisible(true);
    }
  }, [scrollTop]);

  // ===========================================================================
  // render

  return (
    <>
      {(isDesktop() && (
        <>
          <div
            ref={scalingLogoRef}
            css={[
              css`
                width: 100vw;
                max-width: 1600px;
              `,
              tw`relative`
            ]}
          />

          <div
            css={[
              css`
                transition: ${scalingLogoVisible ? `0.3s` : `0.3s`}
                  ${A.EASING_CUBIC} all;

                transform: translate3d(
                  0,
                  ${scalingLogoVisible ? `0` : `calc(-15vw + 4rem)`},
                  0
                );

                display: ${pathname !== `/` ? `none` : `flex`};

                height: 15vw;

                @media screen and (min-width: 1601px) {
                  height: 240px;
                  transform: translate3d(
                    0,
                    ${scalingLogoVisible ? `0` : `-172px`},
                    0
                  );
                }
              `,
              tw`w-full fixed top-0 right-0 bottom-0 left-0 z-20 items-center justify-center bg-white`
            ]}
          >
            <div
              css={[
                css`
                  transition: 0.3s ${A.EASING_CUBIC} transform;
                  transform: scale(
                    ${scalingLogoVisible ? 1 : scaleTransformFactor}
                  );
                  transform-origin: 50% 100%;

                  width: 100vw;
                  max-width: 1600px;
                `,
                tw`relative`
              ]}
            >
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appearDown`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `0.4s`
                    )}
                  `
                ]}
              >
                <Logo.Wordmark
                  inject={{
                    tw: tw`w-full block`
                  }}
                />
              </div>
            </div>
          </div>

          <header
            css={[
              css`
                transition: 0.3s ease opacity;
                opacity: ${visible ? `1` : `0`};
                background: ${pathname === `/`
                  ? `transparent`
                  : theme`colors.white`};
              `,
              tw`w-full h-16 fixed top-0 right-0 left-0 z-30`
            ]}
          >
            <Grid inject={{ tw: tw`h-full items-center` }}>
              <ul css={[tw`col-span-4 h-full relative flex items-center`]}>
                <li
                  css={[
                    css`
                      transition: opacity 0.3s ${A.EASING_CUBIC},
                        transform 0.3s ${A.EASING_CUBIC};

                      transform: translate3d(
                        0,
                        ${subnavVisible ? `0` : `-1rem`},
                        0
                      );
                      opacity: ${subnavVisible ? 1 : 0};

                      text-decoration: ${pathname === `/products`
                        ? `underline`
                        : `none`};

                      @media not all and (pointer: coarse) {
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    `
                  ]}
                >
                  <Go to="/products">
                    <T.Body
                      font="2"
                      inject={{
                        css: `font-weight: 700;`,
                        tw: tw`mr-6 uppercase`
                      }}
                    >
                      Shop
                    </T.Body>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      transition: opacity 0.3s ${A.EASING_CUBIC},
                        transform 0.3s ${A.EASING_CUBIC};

                      transform: translate3d(
                        0,
                        ${subnavVisible ? `0` : `-1rem`},
                        0
                      );
                      opacity: ${subnavVisible ? 1 : 0};

                      text-decoration: ${pathname === `/about`
                        ? `underline`
                        : `none`};

                      @media not all and (pointer: coarse) {
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    `
                  ]}
                >
                  <Go to="/about">
                    <T.Body
                      font="2"
                      inject={{
                        css: `
                      font-weight: 700;
                    `,
                        tw: tw`mr-6 uppercase`
                      }}
                    >
                      About
                    </T.Body>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      transition: opacity 0.3s ${A.EASING_CUBIC},
                        transform 0.3s ${A.EASING_CUBIC};

                      transform: translate3d(
                        0,
                        ${subnavVisible ? `0` : `-1rem`},
                        0
                      );
                      opacity: ${subnavVisible ? 1 : 0};

                      text-decoration: ${pathname === `/features`
                        ? `underline`
                        : `none`};

                      @media not all and (pointer: coarse) {
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    `
                  ]}
                >
                  <Go to="/features">
                    <T.Body
                      font="2"
                      inject={{
                        css: `
                      font-weight: 700;
                    `,
                        tw: tw`mr-6 uppercase`
                      }}
                    >
                      Features
                    </T.Body>
                  </Go>
                </li>
              </ul>

              <div
                css={[
                  css`
                    opacity: ${pathname === `/` ? 0 : 1};
                  `,
                  tw`col-span-4 h-full relative flex items-center justify-center`
                ]}
              >
                <div ref={logoRef}>
                  <Go to="/">
                    <Logo.Wordmark inject={{ tw: tw`h-8` }} />
                  </Go>
                </div>
              </div>

              <div
                css={[
                  css`
                    transition: opacity 0.3s ${A.EASING_CUBIC},
                      transform 0.3s ${A.EASING_CUBIC};

                    transform: translate3d(
                      0,
                      ${subnavVisible ? `0` : `-1rem`},
                      0
                    );
                    opacity: ${subnavVisible ? 1 : 0};
                  `,
                  tw`col-span-4 col-start-9 flex items-center justify-end`
                ]}
              >
                <button
                  type="button"
                  css={[
                    css`
                      text-decoration: ${pathname === `/cart`
                        ? `underline`
                        : `none`};

                      @media not all and (pointer: coarse) {
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    `
                  ]}
                  onClick={() => setCartActive(true)}
                >
                  <T.Body
                    font="2"
                    inject={{
                      css: `
                      font-weight: 700;
                    `,
                      tw: tw`uppercase`
                    }}
                  >
                    Cart ({cartCount})
                  </T.Body>
                </button>
              </div>
            </Grid>
          </header>
        </>
      )) || (
        <>
          <header
            css={[
              css`
                transition: background 0.3s ${A.EASING_CUBIC};

                ${!cartActive &&
                menuActive &&
                `background: ${theme`colors.black`};`}
                ${cartActive &&
                !menuActive &&
                `background: ${theme`colors.green`};`}
                ${!cartActive &&
                !menuActive &&
                `background: ${theme`colors.white`};`}
              `,
              tw`w-full h-16 fixed top-0 right-0 left-0 z-50 border-black border-b`
            ]}
          >
            <div
              css={[
                css`
                  transition: opacity 0.3s ${A.EASING_CUBIC},
                    transform 0.3s ${A.EASING_CUBIC};

                  transform: translate3d(
                    0,
                    ${pathname !== `/` || scalingLogoVisible ? `0` : `-1rem`},
                    0
                  );
                  opacity: ${!menuActive &&
                  pathname === `/` &&
                  scalingLogoVisible
                    ? 1
                    : 0};
                `,
                tw`w-full h-16 absolute top-0 right-0 left-0 flex items-center justify-center py-2 px-1 pointer-events-none bg-white`
              ]}
            >
              <Go to="/" inject={{ tw: tw`w-full relative` }}>
                <Logo.Wordmark inject={{ tw: tw`w-full relative block` }} />
              </Go>
            </div>

            <div
              css={[
                css`
                  transition: opacity 0.3s ${A.EASING_CUBIC},
                    transform 0.3s ${A.EASING_CUBIC};

                  transform: translate3d(
                    0,
                    ${!menuActive && pathname === `/` && scalingLogoVisible
                      ? `-1rem`
                      : `0`},
                    0
                  );
                  opacity: ${!menuActive &&
                  pathname === `/` &&
                  scalingLogoVisible
                    ? 0
                    : 1};
                `,
                tw`w-full h-16 relative flex items-center justify-center`
              ]}
            >
              <Grid>
                <div css={[tw`col-span-2`]}>
                  <button
                    type="button"
                    css={[tw`w-12 h-12 relative block -ml-3 p-3`]}
                    onClick={() => setMenuActive(!menuActive)}
                  >
                    <div
                      css={[
                        css`
                          .header-menu-line {
                            transition: transform 0.5s ${A.EASING_CUBIC};
                          }

                          .header-menu-line--0 {
                            transform: translate3d(
                                0,
                                ${menuActive ? `8px` : `0`},
                                0
                              )
                              rotate(${menuActive ? `45deg` : 0});
                          }

                          .header-menu-line--1 {
                            transform: scaleX(${menuActive ? 0 : 1});
                          }

                          .header-menu-line--2 {
                            transform: translate3d(
                                0,
                                ${menuActive ? `-8px` : `0`},
                                0
                              )
                              rotate(${menuActive ? `-45deg` : 0});
                          }
                        `,
                        tw`w-12 absolute top-0 right-0 bottom-0 left-0 z-10 flex flex-col items-center justify-center px-3`
                      ]}
                    >
                      <div
                        className="header-menu-line header-menu-line--0"
                        css={[
                          css`
                            width: 100%;
                            height: 4px;
                            background: ${menuActive
                              ? theme`colors.white`
                              : theme`colors.black`};
                          `
                        ]}
                      />
                      <div
                        className="header-menu-line header-menu-line--1"
                        css={[
                          css`
                            width: 100%;
                            height: 4px;
                            margin: 4px 0;
                            background: ${menuActive
                              ? theme`colors.white`
                              : theme`colors.black`};
                          `
                        ]}
                      />
                      <div
                        className="header-menu-line header-menu-line--2"
                        css={[
                          css`
                            width: 100%;
                            height: 4px;
                            background: ${menuActive
                              ? theme`colors.white`
                              : theme`colors.black`};
                          `
                        ]}
                      />
                    </div>

                    {/* <Icon.Menu
                      color={
                        menuActive ? theme`colors.white` : theme`colors.black`
                      }
                      inject={{ tw: tw`w-full opacity-50` }}
                    /> */}
                  </button>
                </div>

                <Go
                  to="/"
                  inject={{
                    css: css`
                      // height: 90%;
                    `,
                    tw: tw`col-span-8 relative flex items-center justify-center`
                  }}
                >
                  <Logo.Wordmark
                    color={
                      menuActive ? theme`colors.white` : theme`colors.black`
                    }
                    inject={{ tw: tw`w-full h-7 relative block` }}
                  />
                </Go>

                <div css={[tw`col-span-2 flex justify-end`]}>
                  <button
                    type="button"
                    css={[
                      css`
                        padding: 0.4rem;
                      `,
                      tw`w-12 h-12 relative block -mr-2`
                    ]}
                    onClick={() => setCartActive(!cartActive)}
                  >
                    <Icon.Cart
                      color={
                        menuActive ? theme`colors.white` : theme`colors.black`
                      }
                      inject={{ tw: tw`w-full` }}
                    />
                  </button>
                </div>
              </Grid>
            </div>
          </header>
        </>
      )}
    </>
  );
};

export default Header;
